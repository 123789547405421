import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LoginPage from '../pages/auth/login';
import HomePage from '../pages/main/home';
import PrivateRoute from './PrivateRoute';

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/home"
        element={
          <PrivateRoute>
            <HomePage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
