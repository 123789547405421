import React from 'react';

const HomePage: React.FC = () => (
  <div>
    <h1>Home Page</h1>
    {/* Add content specific to the home page */}
  </div>
);

export default HomePage;
