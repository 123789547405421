import React from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import { useAuthStore } from '../../store/AuthStore'; // Import the Zustand store

type PrivateRouteProps = RouteProps & {
  children?: React.ReactNode;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, ...rest }) => {
  const { isAuthenticated } = useAuthStore((state) => state);

  if (!isAuthenticated) {
    // user is not authenticated
    return <Navigate to="/" />;
  }

  return <>{children}</>; // Wrap children in an empty fragment to ensure it's a valid React element
};

export default PrivateRoute;
